<template>
  <div>
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <this-header />

      <div class="container-new pd2">
        <div class="d-flex align-items-center">
          <router-link
            v-if="UserData.role
              == 'banhuay'"
            :to="{ name: 'tanghuay-home' }"
          >
            <button
              class="bnt-home"
            >
              <i class="fas fa-home" />
            </button>
          </router-link>
          <div class="txt-right d-flex justify-content-between">
            <span class="txt-right__text">หวย{{ NameShow }}</span>
            <span class="txt-right__text"><i class="far fa-clock text-danger" /> {{ CloseTimeShow ? CloseTimeShow : '-' }} ชม.</span>
          </div>
        </div>

        <div v-if="!payOut">
          <div
            class="d-flex align-items-center justify-content-between"
            style="margin-top: 10px"
          >
            <router-link :to="{ name: 'tanghuay-round' }">
              <span class="text-white"> <i class="far fa-arrow-circle-left" /> ย้อนกลับ</span>
            </router-link>
            <div class="">
              <b-button
                size="sm"
                style="height: 25px !important "
                @click="showRule(Title.LottoName)"
              >
                กติกา
              </b-button>
            </div>
          </div>

          <div
            class="card-tang"
          >
            <div class="d-flex justify-content-between align-items-center">
              <div
                class="select-one cursor-pointer"
                :class="{actives : !Mode}"
                @click="Mode = false,selectNumbet2U = false,selectNumbet2D = false,selectNumbet2T = false"
              >
                เลือกจากแผง
              <!-- {{ MainWallet }} -->
              </div>
              <div
                class="select-two cursor-pointer"
                :class="{actives : Mode}"
                @click="Mode = true"
              >
                กดเลข
              </div>
            </div>

            <!-- เลือกหวยจากแผง -->

            <div
              class="app__lotto-slug slug-classic pp"
              style="margin-top: 3px;"
            >
              <div
                class="slug-item cursor-pointer"
                :class="{active: selectNumbet3U}"
                @click="TogType('up',3)"
              >
                <span class="label">3 ตัวบน</span> <span class="rate">{{ RateAll.Three.Up.Rate }}</span>
              </div>
              <div
                class="slug-item cursor-pointer"
                :class="{active: selectNumbet3T}"
                @click="TogType('tot',3)"
              >
                <span class="label">3 ตัวโต๊ด</span> <span class="rate">{{ RateAll.Three.Tot.Rate }}</span>
              </div>
              <div
                class="slug-item cursor-pointer"
                :class="{active: selectNumbet3D}"
                @click="TogType('flip',3)"
              >
                <span class="label">3 ตัวกลับ</span> <span class="rate">ตัวเลือก</span>
              </div>
              <div
                class="slug-item cursor-pointer"
                :class="{active: selectNumbet2U}"
                @click="TogType('up',2)"
              >
                <span class="label">2 ตัวบน</span> <span class="rate">{{ RateAll.Two.Up.Rate }}</span>
              </div>
              <div
                class="slug-item cursor-pointer"
                :class="{active: selectNumbet2D}"
                @click="TogType('down',2)"
              >
                <span class="label">2 ตัวล่าง</span> <span class="rate">{{ RateAll.Two.Down.Rate }}</span>
              </div>
              <div
                class="slug-item cursor-pointer"
                :class="{active: selectNumbet2T}"
                @click="TogType('flip',2)"
              >
                <span class="label">2 ตัวกลับ</span> <span class="rate">ตัวเลือก</span>
              </div>
              <div
                class="slug-item cursor-pointer slug-item-lg"
                :class="{active: selectNumbet1U}"
                @click="TogType('up',1)"
              >
                <span class="label">วิ่งบน</span> <span class="rate">{{ RateAll.One.Up.Rate }}</span>
              </div>
              <div
                class="slug-item cursor-pointer slug-item-lg"
                :class="{active: selectNumbet1D}"
                @click="TogType('down',1)"
              >
                <span class="label">วิ่งล่าง</span> <span class="rate">{{ RateAll.One.Down.Rate }}</span>
              </div>
            </div>
            <div>

              <div v-if="!Mode">
                <hr
                  style="border-top: 1px solid #1e1e1e"
                  class="mt-1 mb-1"
                >
                <!-- หวย 3 ตัว -->
                <ul
                  v-if="selectNumbet3U || selectNumbet3T"
                  class="bet-group pp"
                >
                  <li
                    class="number-item"
                    :class="{active: SubThree0}"
                    @click="TogThreeType(0,3)"
                  >
                    <span class="label">000</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree1}"
                    @click="TogThreeType(100,3)"
                  >
                    <span class="label">100</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree2}"
                    @click="TogThreeType(200,3)"
                  >
                    <span class="label">200</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree3}"
                    @click="TogThreeType(300,3)"
                  >
                    <span class="label">300</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree4}"
                    @click="TogThreeType(400,3)"
                  >
                    <span class="label">400</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree5}"
                    @click="TogThreeType(500,3)"
                  >
                    <span class="label">500</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree6}"
                    @click="TogThreeType(600,3)"
                  >
                    <span class="label">600</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree7}"
                    @click="TogThreeType(700,3)"
                  >
                    <span class="label">700</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree8}"
                    @click="TogThreeType(800,3)"
                  >
                    <span class="label">800</span>
                  </li>
                  <li
                    class="number-item"
                    :class="{active: SubThree9}"
                    @click="TogThreeType(900,3)"
                  >
                    <span class="label">900</span>
                  </li>
                </ul>

                <!-- หัว 2 ตัว -->
                <div
                  v-if="selectNumbet2U || selectNumbet2D"
                  class="row pp m-0"
                >
                  <div class="col-12 col-lg-4">
                    <strong>19 ประตู</strong>
                    <ul class="bet-quick">
                      <li
                        class="quick-item"
                        :class="{active: (NineDoor.D0 = CheckTog('nine',PickTwoArr,0)).status}"
                        @click="CheckPickNum(NineDoor.D0.SwipChang,PickTwoArr,NineDoor.D0.status)"
                      >
                        0
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D1 = CheckTog('nine',PickTwoArr,1)).status}"
                        @click="CheckPickNum(NineDoor.D1.SwipChang,PickTwoArr,NineDoor.D1.status)"
                      >
                        1
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D2 = CheckTog('nine',PickTwoArr,2)).status}"
                        @click="CheckPickNum(NineDoor.D2.SwipChang,PickTwoArr,NineDoor.D2.status)"
                      >
                        2
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D3 = CheckTog('nine',PickTwoArr,3)).status}"
                        @click="CheckPickNum(NineDoor.D3.SwipChang,PickTwoArr,NineDoor.D3.status)"
                      >
                        3
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D4 = CheckTog('nine',PickTwoArr,4)).status}"
                        @click="CheckPickNum(NineDoor.D4.SwipChang,PickTwoArr,NineDoor.D4.status)"
                      >
                        4
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D5 = CheckTog('nine',PickTwoArr,5)).status}"
                        @click="CheckPickNum(NineDoor.D5.SwipChang,PickTwoArr,NineDoor.D5.status)"
                      >
                        5
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D6 = CheckTog('nine',PickTwoArr,6)).status}"
                        @click="CheckPickNum(NineDoor.D6.SwipChang,PickTwoArr,NineDoor.D6.status)"
                      >
                        6
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D7 = CheckTog('nine',PickTwoArr,7)).status}"
                        @click="CheckPickNum(NineDoor.D7.SwipChang,PickTwoArr,NineDoor.D7.status)"
                      >
                        7
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D8 = CheckTog('nine',PickTwoArr,8)).status}"
                        @click="CheckPickNum(NineDoor.D8.SwipChang,PickTwoArr,NineDoor.D8.status)"
                      >
                        8
                      </li><li
                        class="quick-item"
                        :class="{active: (NineDoor.D9 = CheckTog('nine',PickTwoArr,9)).status}"
                        @click="CheckPickNum(NineDoor.D9.SwipChang,PickTwoArr,NineDoor.D9.status)"
                      >
                        9
                      </li>
                    </ul>
                  </div>
                  <div

                    class="col-12 col-lg-4"
                  >
                    <strong>รูดหน้า</strong> <ul class="bet-quick">
                      <li
                        class="quick-item"
                        :class="{active: (RootFront.D0 = CheckTog('front',PickTwoArr,0)).status}"
                        @click="CheckPickNum(RootFront.D0.SwipChang,PickTwoArr,RootFront.D0.status)"
                      >
                        0
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D1 = CheckTog('front',PickTwoArr,1)).status}"
                        @click="CheckPickNum(RootFront.D1.SwipChang,PickTwoArr,RootFront.D1.status)"
                      >
                        1
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D2 = CheckTog('front',PickTwoArr,2)).status}"
                        @click="CheckPickNum(RootFront.D2.SwipChang,PickTwoArr,RootFront.D2.status)"
                      >
                        2
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D3 = CheckTog('front',PickTwoArr,3)).status}"
                        @click="CheckPickNum(RootFront.D3.SwipChang,PickTwoArr,RootFront.D3.status)"
                      >
                        3
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D4 = CheckTog('front',PickTwoArr,4)).status}"
                        @click="CheckPickNum(RootFront.D4.SwipChang,PickTwoArr,RootFront.D4.status)"
                      >
                        4
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D5 = CheckTog('front',PickTwoArr,5)).status}"
                        @click="CheckPickNum(RootFront.D5.SwipChang,PickTwoArr,RootFront.D5.status)"
                      >
                        5
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D6 = CheckTog('front',PickTwoArr,6)).status}"
                        @click="CheckPickNum(RootFront.D6.SwipChang,PickTwoArr,RootFront.D6.status)"
                      >
                        6
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D7 = CheckTog('front',PickTwoArr,7)).status}"
                        @click="CheckPickNum(RootFront.D7.SwipChang,PickTwoArr,RootFront.D7.status)"
                      >
                        7
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D8 = CheckTog('front',PickTwoArr,8)).status}"
                        @click="CheckPickNum(RootFront.D8.SwipChang,PickTwoArr,RootFront.D8.status)"
                      >
                        8
                      </li><li
                        class="quick-item"
                        :class="{active: (RootFront.D9 = CheckTog('front',PickTwoArr,9)).status}"
                        @click="CheckPickNum(RootFront.D9.SwipChang,PickTwoArr,RootFront.D9.status)"
                      >
                        9
                      </li>
                    </ul>
                  </div> <div class="col-12 col-xl-4">
                    <strong>รูดหลัง</strong> <ul class="bet-quick">
                      <li
                        class="quick-item"
                        :class="{active: (RootBack.D0 = CheckTog('back',PickTwoArr,0)).status}"
                        @click="CheckPickNum(RootBack.D0.SwipChang,PickTwoArr,RootBack.D0.status)"
                      >
                        0
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D1 = CheckTog('back',PickTwoArr,1)).status}"
                        @click="CheckPickNum(RootBack.D1.SwipChang,PickTwoArr,RootBack.D1.status)"
                      >
                        1
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D2 = CheckTog('back',PickTwoArr,2)).status}"
                        @click="CheckPickNum(RootBack.D2.SwipChang,PickTwoArr,RootBack.D2.status)"
                      >
                        2
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D3 = CheckTog('back',PickTwoArr,3)).status}"
                        @click="CheckPickNum(RootBack.D3.SwipChang,PickTwoArr,RootBack.D3.status)"
                      >
                        3
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D4 = CheckTog('back',PickTwoArr,4)).status}"
                        @click="CheckPickNum(RootBack.D4.SwipChang,PickTwoArr,RootBack.D4.status)"
                      >
                        4
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D5 = CheckTog('back',PickTwoArr,5)).status}"
                        @click="CheckPickNum(RootBack.D5.SwipChang,PickTwoArr,RootBack.D5.status)"
                      >
                        5
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D6 = CheckTog('back',PickTwoArr,6)).status}"
                        @click="CheckPickNum(RootBack.D6.SwipChang,PickTwoArr,RootBack.D6.status)"
                      >
                        6
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D7 = CheckTog('back',PickTwoArr,7)).status}"
                        @click="CheckPickNum(RootBack.D7.SwipChang,PickTwoArr,RootBack.D7.status)"
                      >
                        7
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D8 = CheckTog('back',PickTwoArr,8)).status}"
                        @click="CheckPickNum(RootBack.D8.SwipChang,PickTwoArr,RootBack.D8.status)"
                      >
                        8
                      </li><li
                        class="quick-item"
                        :class="{active: (RootBack.D9 = CheckTog('back',PickTwoArr,9)).status}"
                        @click="CheckPickNum(RootBack.D9.SwipChang,PickTwoArr,RootBack.D9.status)"
                      >
                        9
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- เลขวิ่ง -->
                <ul
                  v-if="selectNumbet1D || selectNumbet1U"
                  class="bet-number pp "
                >
                  <li
                    v-for="item in PickOneArr"
                    :key="item.Num"
                    :class="{activede: ActiveBet(item)}"
                    class="number-item number-lg"
                    @click="PickNum(item,PickOneArr)"
                  >
                    <span class="label">{{ item.Num }}</span>
                  </li>
                </ul>

                <!-- เลือกจากแผง -->
                <ul
                  v-if="selectNumbet3U || selectNumbet3T"
                  class="bet-number pp "
                >
                  <li
                    v-for="item in 100"
                    :key="item.num"
                    :class="{active: ActiveBet(PickThreeArr[(item-1)+PickThree])}"
                    class="number-item2"
                    @click="PickNum(PickThreeArr[(item-1)+PickThree],PickThreeArr)"
                  >
                    {{ PickThreeArr[(item-1)+PickThree].Num }}
                  </li>
                </ul>
                <ul
                  v-if="selectNumbet2U || selectNumbet2D"
                  class="bet-number pp "
                >
                  <li
                    v-for="item in PickTwoArr"
                    :key="item.Num"
                    :class="{active: ActiveBet(item)}"
                    class="number-item2"
                    @click="PickNum(item,PickTwoArr)"
                  >
                    {{ item.Num }}
                  </li>
                </ul>

              </div>

            </div>
            <!-- <div class="padding-bottom-10" /> -->
            <!-- สิ้นสุดเลือกหวยจากแผง -->

            <!-- เลือกหวยกดเลข -->
            <div v-if="Mode">
              <ul
                v-if="selectNumbet2U || selectNumbet2D"
                class="bet-group pp"
              >
                <li
                  class="number-item"
                  :class="{active: TwoPushSubType.NineDoor}"
                  @click="TwoPushSubType.NineDoor = !TwoPushSubType.NineDoor,TwoPushSubType.RootFront = false,TwoPushSubType.RootBack = false"
                >
                  <span class="label">19 ประตู</span>
                </li>
                <li
                  class="number-item"
                  :class="{active: TwoPushSubType.RootFront}"
                  @click="TwoPushSubType.RootFront = !TwoPushSubType.RootFront,TwoPushSubType.NineDoor = false,TwoPushSubType.RootBack = false"
                >
                  <span class="label">รูดหน้า</span>
                </li>
                <li
                  class="number-item"
                  :class="{active: TwoPushSubType.RootBack}"
                  @click="TwoPushSubType.RootBack = !TwoPushSubType.RootBack ,TwoPushSubType.NineDoor = false,TwoPushSubType.RootFront =false"
                >
                  <span class="label">รูดหลัง</span>
                </li>
                <li
                  class="number-item"
                  :class="{active: TwoPushSubType.Ble}"
                  @click="SelctTwoTool('เลขเบิ้ล')"
                >
                  <span class="label">เลขเบิ้ล</span>
                </li>
                <li
                  class="number-item"
                  :class="{active: TwoPushSubType.TwoHight}"
                  @click="SelctTwoTool('สองตัวสูง')"
                >
                  <span class="label">สองตัวสูง</span>
                </li>
                <li
                  class="number-item"
                  :class="{active: TwoPushSubType.TwoLow}"
                  @click="SelctTwoTool('สองตัวต่ำ')"
                >
                  <span class="label">สองตัวต่ำ</span>
                </li>
                <li
                  class="number-item"
                  :class="{active: TwoPushSubType.TwoEven}"
                  @click="SelctTwoTool('สองตัวคู่')"
                >
                  <span class="label">สองตัวคู่</span>
                </li>
                <li
                  class="number-item"
                  :class="{active: TwoPushSubType.TwoOdd}"
                  @click="SelctTwoTool('สองตัวคี่')"
                >
                  <span class="label">สองตัวคี่</span>
                </li>
              </ul>
              <hr
                style="border-top: 1px solid #1e1e1e"
                class="mt-1 mb-1"
              >

              <h6
                v-if="!CheckBetType"
                class="text-center"
              >
                ระบุตัวเลข {{ CalMaxLength() }} ตัว
              </h6>
              <h6
                v-else
                class="text-center"
              >
                กรุณาเลือกประเภท
              </h6>
              <!-- {{ CheckBetType }} -->
              <div class="text-center mt-1">
                <otp-input
                  v-if="BugOtp === true"
                  v-model="NumBet"
                  class="style-1"
                  :length="CalMaxLength()"
                  pattern="[^0-9]+"
                  :ignore-pattern="false"
                  :size="32"
                  :disabled="CheckBetType"
                  @valid="handleOnComplete()"
                />
              </div>
              <div class="row pl-1 pr-1">
                <div class="col-4 mmb-1">
                  <button
                    data-id="1"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(1)"
                  >
                    1
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="2"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(2)"
                  >
                    2
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="3"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(3)"
                  >
                    3
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="4"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(4)"
                  >
                    4
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="5"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(5)"
                  >
                    5
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="6"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(6)"
                  >
                    6
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="7"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(7)"
                  >
                    7
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="8"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(8)"
                  >
                    8
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="9"
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(9)"
                  >
                    9
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <!-- @click="NumBet = ''" -->
                  <button
                    v-b-modal.modal-1
                    class="button-num-cals"
                    @click="Undo()"
                  >
                    ย้อนกลับ
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    class="button-num-cal push-btn-effect"
                    :disabled="CheckBetType"
                    @click="Betting(0)"
                  >
                    0
                  </button>
                </div>
                <div class="col-4 mmb-1">
                  <button
                    data-id="delete"
                    class="button-num-calss"
                    @click="NumBet = NumBet.slice(0, -1)"
                  >
                    <i
                      aria-hidden="true"
                      class="fas fa-backspace"
                    />
                  </button>
                </div>
              </div>
            </div>

            <div
              v-if="!(selectNumbet3U || selectNumbet3T || selectNumbet2U || selectNumbet2D || selectNumbet1D || selectNumbet1U)"
              class="card__loading"
            >
              <i class="icon fas fa-mouse-pointer" /> <span class="label mb-2">โปรดเลือกประเภท</span>
            </div>
          </div>

          <div
            v-if="selectNumbet3U || selectNumbet3T || selectNumbet2U || selectNumbet2D"
            class="padding-bottom-10"
          />
        </div>
        <div v-if="payOut">
          <div
            class="d-flex align-items-center justify-content-between"
            style="margin-top: 10px"
          >
            <a @click="payOut = !payOut,confirm = false">
              <span class="text-white"> <i class="far fa-arrow-circle-left" /> ย้อนกลับ</span>
            </a>
          </div>

          <div class="card-tang">
            <div class="nav-text pd-h-20 text-center pt-1 pb-1 font-medium-3">
              รายการแทงทั้งหมด {{ items.length }} รายการ
            </div>

            <div
              v-if="items.filter(ele => ele.num_type == '3ตัวบน' ).length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  3 ตัวบน
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div> <div class="stake-rate">
                    อัตราจ่าย
                  </div> <div class="stake-win-rate">
                    ชนะ
                  </div> <div class="stake-amount">
                    จำนวนเงิน
                  </div> <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesU3">
                  <div
                    v-for="item, index in items.filter(ele => ele.num_type == '3ตัวบน' )"
                    :key="item.id"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}

                    </div> <div class="stake-number">
                      {{ item.num_lot }}
                    </div> <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div> <div class="stake-win-rate">
                      <span class="rate-wrapper">{{ Commas(item.win_rate * item.price) }}</span>
                    </div>
                    <div class="stake-amount">

                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot,
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max,
                            ) === true
                            ? `ระบุจำนวนเงิน ${Commas(
                              CheckAmount(item.num_type).Min,
                            )}-${Commas(
                              CheckAmount(item.num_type).Max,
                            )} บาท`
                            : ''
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>

                    <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div> <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="RemovePush(items,item.num_lot,item.num_type),DisableInList(PickThreeArr,'U3',item.num_lot)"
                      >
                        ลบ
                      </button>
                    </div>

                  <!-- {{ item.win }} -->
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="items.filter(ele => ele.num_type == '3ตัวโต๊ด' ).length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  3 ตัวโต๊ด
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div> <div class="stake-rate">
                    อัตราจ่าย
                  </div> <div class="stake-win-rate">
                    ชนะ
                  </div> <div class="stake-amount">
                    จำนวนเงิน
                  </div> <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesT3">
                  <div
                    v-for="item, index in items.filter(ele => ele.num_type == '3ตัวโต๊ด' )"
                    :key="item.id"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}

                    </div> <div class="stake-number">
                      {{ item.num_lot }}
                    </div> <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div> <div class="stake-win-rate">
                      <span class="rate-wrapper">{{ Commas(item.win_rate * item.price) }}</span>
                    </div>
                    <div class="stake-amount">

                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot,
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max,
                            ) === true
                            ? `ระบุจำนวนเงิน ${Commas(
                              CheckAmount(item.num_type).Min,
                            )}-${Commas(
                              CheckAmount(item.num_type).Max,
                            )} บาท`
                            : ''
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                    <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div> <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="RemovePush(items,item.num_lot,item.num_type),DisableInList(PickThreeArr,'T3',item.num_lot)"
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="items.filter(ele => ele.num_type == '2ตัวบน' ).length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  2 ตัวบน
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div> <div class="stake-rate">
                    อัตราจ่าย
                  </div> <div class="stake-win-rate">
                    ชนะ
                  </div> <div class="stake-amount">
                    จำนวนเงิน
                  </div> <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesU2">
                  <div
                    v-for="item, index in items.filter(ele => ele.num_type == '2ตัวบน' )"
                    :key="item.num_lot"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}

                    </div> <div class="stake-number">
                      {{ item.num_lot }}
                    </div> <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div> <div class="stake-win-rate">
                      <span class="rate-wrapper">{{ Commas(item.win_rate * item.price) }}</span>
                    </div> <div class="stake-amount">

                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot,
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max,
                            ) === true
                            ? `ระบุจำนวนเงิน ${Commas(
                              CheckAmount(item.num_type).Min,
                            )}-${Commas(
                              CheckAmount(item.num_type).Max,
                            )} บาท`
                            : ''
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                    <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div> <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="RemovePush(items,item.num_lot,item.num_type),DisableInList(PickThreeArr,'U2',item.num_lot)"
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="items.filter(ele => ele.num_type == '2ตัวล่าง' ).length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  2 ตัวล่าง
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div> <div class="stake-rate">
                    อัตราจ่าย
                  </div> <div class="stake-win-rate">
                    ชนะ
                  </div> <div class="stake-amount">
                    จำนวนเงิน
                  </div> <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesD2">
                  <div
                    v-for="item, index in items.filter(ele => ele.num_type == '2ตัวล่าง' )"
                    :key="item.num_lot"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}

                    </div> <div class="stake-number">
                      {{ item.num_lot }}
                    </div> <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div> <div class="stake-win-rate">
                      <span class="rate-wrapper">{{ Commas(item.win_rate * item.price) }}</span>
                    </div> <div class="stake-amount">

                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot,
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max,
                            ) === true
                            ? `ระบุจำนวนเงิน ${Commas(
                              CheckAmount(item.num_type).Min,
                            )}-${Commas(
                              CheckAmount(item.num_type).Max,
                            )} บาท`
                            : ''
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                    <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div> <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="RemovePush(items,item.num_lot,item.num_type),DisableInList(PickThreeArr,'D2',item.num_lot)"
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="items.filter(ele => ele.num_type == 'วิ่งบน' ).length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  วิ่งบน
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div> <div class="stake-rate">
                    อัตราจ่าย
                  </div> <div class="stake-win-rate">
                    ชนะ
                  </div> <div class="stake-amount">
                    จำนวนเงิน
                  </div> <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesU1">
                  <div
                    v-for="item, index in items.filter(ele => ele.num_type == 'วิ่งบน' )"
                    :key="item.num_lot"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}

                    </div> <div class="stake-number">
                      {{ item.num_lot }}
                    </div> <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div> <div class="stake-win-rate">
                      <span class="rate-wrapper">{{ Commas(item.win_rate * item.price) }}</span>
                    </div> <div class="stake-amount">

                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot,
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max,
                            ) === true
                            ? `ระบุจำนวนเงิน ${Commas(
                              CheckAmount(item.num_type).Min,
                            )}-${Commas(
                              CheckAmount(item.num_type).Max,
                            )} บาท`
                            : ''
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                    <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div> <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="RemovePush(items,item.num_lot,item.num_type),DisableInList(PickThreeArr,'U1',item.num_lot)"
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

            <div
              v-if="items.filter(ele => ele.num_type == 'วิ่งล่าง' ).length > 0"
              class="pp"
            >
              <div class="d-flex">
                <h6 class="mb-0 pp">
                  วิ่งล่าง
                </h6>
              </div>
              <div class="stake-list">
                <div class="stake-header">
                  <div class="stake-no" />
                  <div class="stake-number">
                    เลขที่แทง
                  </div> <div class="stake-rate">
                    อัตราจ่าย
                  </div> <div class="stake-win-rate">
                    ชนะ
                  </div> <div class="stake-amount">
                    จำนวนเงิน
                  </div> <div class="stake-action" />
                </div>
                <validation-observer ref="BetItemsRulesD1">
                  <div
                    v-for="item, index in items.filter(ele => ele.num_type == 'วิ่งล่าง' )"
                    :key="item.num_lot"
                    class="stake-item"
                  >
                    <div class="stake-no">
                      {{ index + 1 }}

                    </div> <div class="stake-number">
                      {{ item.num_lot }}
                    </div> <div class="stake-rate">
                      <span class="rate-wrapper">{{ item.win_rate }}</span>
                    </div> <div class="stake-win-rate">
                      <span class="rate-wrapper">{{ Commas(item.win_rate * item.price) }}</span>
                    </div> <div class="stake-amount">

                      <validation-provider
                        v-if="!confirm"
                        #default="{ errors }"
                        :rules="`required|between:${
                          CheckAmount(item.num_type).Min
                        },${CheckAmount(item.num_type).Max}`"
                        name="Number between"
                      >
                        <b-form-input
                          v-model="item.price"
                          type="number"
                          class="amount-input"
                          placeholder=""
                          @change="
                            CheckAmount(
                              item.num_type,
                              item.price,
                              index,
                              item.num_lot,
                            )
                          "
                        />
                        <!-- @change="CheckAmount(item.num_type,item.price,index)" -->
                        <small class="text-danger">{{
                          errors[0] ||
                            TestPrice(
                              item.price,
                              CheckAmount(item.num_type).Min,
                              CheckAmount(item.num_type).Max,
                            ) === true
                            ? `ระบุจำนวนเงิน ${Commas(
                              CheckAmount(item.num_type).Min,
                            )}-${Commas(
                              CheckAmount(item.num_type).Max,
                            )} บาท`
                            : ''
                        }}</small>
                      </validation-provider>
                      <p v-else>
                        {{ item.price }}
                      </p>
                    <!-- <input
                      v-model="item.price"
                      type="text"
                      class="amount-input"
                      @change="
                        CheckAmount(
                          data.item.num_type,
                          data.item.price,
                          data.index,
                          data.item.num_lot,
                        )
                      "
                    > -->
                    </div> <div class="stake-action">
                      <button
                        type="button"
                        class="btne btn-dangere"
                        @click="RemovePush(items,item.num_lot,item.num_type),DisableInList(PickThreeArr,'D1',item.num_lot)"
                      >
                        ลบ
                      </button>
                    </div>

                    {{ item.win }}
                  </div>
                </validation-observer>
              </div>
            </div>

          </div>

          <div
            v-if="confirm == false"
            class="card-tang mb-1"
          >
            <div class="card-bodys">
              <div class="input-group input-group-sm mb-1">
                <div class="input-group-prepend">
                  <span
                    id=""
                    class="input-group-text"
                  >ราคาเท่ากัน</span>
                </div>
                <input
                  v-model="pricebase"
                  type="tel"
                  value="5"
                  maxlength="7"
                  class="form-control"
                  style="padding: 0.25rem 0.5rem;"
                  @input="AddPriceBase(pricebase)"
                >
              </div>
              {{ CheckSubmit }}
              <div class="stake-amount-group">
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(5,pricebase = 5)"
                >
                  5
                </button>
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(10,pricebase = 10)"
                >
                  10
                </button>
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(20,pricebase = 20)"
                >
                  20
                </button>
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(50,pricebase = 50)"
                >
                  50
                </button>
                <button
                  :disabled="CheckSubmit != true"
                  type="button"
                  class="btnw btn-sms btn-outline-red"
                  @click="AddPriceBase(100,pricebase = 100)"
                >
                  100
                </button>
              </div>
              <div class="row">
                <div class="col p-0">
                  <div class="card-tang card-status">
                    <div class="card-headers bg-darker text-white text-center">
                      <small>เครดิตคงเหลือ</small>
                    </div>
                    <div class="card-bodys p-1 text-center">
                      {{ Commas(MainWallet) }} ฿
                    </div>
                  </div>
                </div>
                <div class="col p-0">
                  <div class="card-tang card-status">
                    <div class="card-headers bg-darker text-white text-center">
                      <small>ยอดเดิมพัน</small>
                    </div>
                    <div class="card-bodys p-1 text-center">
                      {{ Commas(CalSumAmount()) }} ฿
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div
            v-if="confirm == false"
            class="row row-sm"
          >
            <div
              class="col-4 p-0"
              style="padding-right: 5px !important"
            >
              <button
                class="btn btn-dangere btn-block btn-sm"
                block
                @click="(Undo(), payOut = !payOut,confirm = false)"
              >
                ล้างโพย
              </button>
            </div>
            <div class="col-8 p-0">
              <button
                class="btn btn-gold btn-block btn-sm"
                block
                @click="PreSubmit()"
              >
                ส่งโพย
              </button>
            </div>
          </div>

          <div
            v-if="confirm == true"
            class="row row-sm mb-4 mt-1"
          >
            <div class="col-4 p-0">
              <button
                type="button"
                class="btn w-100 btn-danger btn-sm"
                @click="confirm = false"
              >
                ยกเลิก
              </button>
            </div>
            <div class="col-8">
              <button
                type="button"
                class="btn w-100 btn-gold btn-sm"
                @click="SummitBet(),payOut = !payOut"
              >
                ยืนยัน
              </button>
            </div>
          </div>

          <div class="padding-bottom-10" />
        </div>
      </div>

      <!-- footer check list -->
      <div
        v-if="!payOut"
        class="check-txt"
      >
        <div
          class="app__lotto-stake-bar"
        >
          <div
            v-if="items.length"
            class="stake-detail cursor-pointer"
            @click="payOut = !payOut"
          >
            <div
              class="label"
            >
              รายการแทงทั้งหมด {{ items.length }} รายการ
            </div>
          </div>
          <div
            v-if="!items.length"
            class="stake-detail no-bet"
          >
            <div
              class="label"
            >
              ยังไม่มีรายการแทง
            </div>
          </div>
          <div class="statk-detail">
            <div />
          </div>
          <div
            v-if="items.length"
            class="stake-action amount"
            @click="payOut = !payOut"
          >
            <div class="label d-flex align-items-center">
              <i
                class="fal fa-usd-circle"
                style="font-size: 20px; margin-right: 5px;"
              />
              ใส่ราคา
            </div>
          </div>

          <div
            v-if="!items.length"
            class="stake-action amount"
          >
            <div class="label d-flex align-items-center">
              <i
                class="fal fa-usd-circle"
                style="font-size: 20px; margin-right: 5px;"
              />
              เลือกเลข
            </div>
          </div>
        </div>
      </div>

      <!-- modal alert -->
      <b-modal
        ref="modal-alert"
        hide-footer
        hide-header
        centered
        size="sm"
        title="Using Component Methods"
      >
        <div class="text-center">
          <b-avatar
            size="100"
            icon="AlertCircleIcon"
            class="mb-1"
            variant="warning"
          >
            <feather-icon
              icon="AlertCircleIcon"
              size="100"
            />
          </b-avatar>
          <h3>แจ้งเตือนปิดรับแทง !!</h3>
          <p>เนื่องจากเลขดังกล่าวได้ถึงยอดที่รับแทงแล้ว</p>

          <b-table
            small
            responsive="sm"
            :items="CloseBetModal"
            :fields="fieldsModal"
          />

          <b-button
            variant="warning"
            @click="showAlertHide()"
          >
            ตกลง
          </b-button>
        </div>
      </b-modal>
      <b-modal
        ref="my-modal"
        centered
        hide-footer
        hide-header
        no-close-on-backdrop
      >
        <div class="text-center mt-2 mb-2">
          <b-avatar
            variant="light-success"
            size="80"
          >
            <i class="fas fa-thumbs-up fa-3x" />
          </b-avatar>

          <h3 class="font-weight-bolder text-success mt-1">
            ระบบได้ทำการส่งโพยของท่านให้เรียบร้อยแล้ว
          </h3>
        </div>
        <table
          style="width: 100%"
          class="pl-1 pr-1 mb-1"
        >
          <tbody>
            <tr>
              <td
                colspan="2"
                class="text-center"
              >
                <h4 class="font-weight-bolder">
                  {{ Slip.LottoHeadName }}
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <small>วันที่</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.date }}
                </h4>
              </td>
              <td>
                <small>เวลา</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.time }} น.
                </h4>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <small>โพยเลขที่</small>
                <h4 class="font-weight-bolder">
                  #{{ Slip.listID }}
                </h4>
              </td>
            </tr>
            <tr>
              <td>
                <small>รายการแทง</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.count }} รายการ
                </h4>
              </td>
              <td>
                <small>ยอดเดิมพันทั้งหมด</small>
                <h4 class="font-weight-bolder">
                  {{ Slip.SumAmount }} บาท
                </h4>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="row">
          <div class="col-6">
            <router-link :to="{path : `/list-huay/${Slip.listID}`}">
              <b-button
                variant="danger"
                block
              >
                ดูโพย
              </b-button>
            </router-link>
          </div>

          <div class="col-6">
            <b-button
              variant="warning"
              block
              style="color: #000 !important"
              @click="hideModal"
            >
              แทงหวยต่อ
            </b-button>
          </div>
        </div>

      </b-modal>
      <this-footer />
    </b-overlay>
  </div>
</template>

<script>
import { required, between, digits } from '@validations'
import {
  BModal,
  BTable,
  BFormInput,
  BAvatar,
  VBToggle,
  BButton,
  // BFormCheckbox,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import vSelect from 'vue-select'
import 'swiper/css/swiper.css'
import momenttz from 'moment-timezone'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OtpInput from '@8bu/vue-otp-input'
import ThisHeader from './component/ThisHeader.vue'
import ThisFooter from './component/ThisFooter.vue'

// import SidebarContent from './SidebarContent.vue'
// import SidebarContentFooter from './SidebarContentFooter.vue'

export default {
  components: {
    BOverlay,
    BIconController,
    OtpInput,
    BModal,
    BTable,
    BButton,
    BFormInput,
    BAvatar,
    ThisHeader,
    ThisFooter,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      Mode: false,
      isActive1: false,
      BetType3: '',
      add_remark: '',
      remark: null,
      discount_member: 0,
      discount: null,
      UserData: JSON.parse(localStorage.getItem('userData')),
      optionsMember: '',
      member: '',
      rule: null,
      confirm: false,
      countArry: 0,
      required,
      between,
      digits,
      payOut: false,
      CheckSubmit: true,
      BugOtp: true,
      show: false,
      ShowLimit: false,
      ShowClose: false,
      fieldsModal: [
        { key: 'type', label: 'ประเภท' },
        { key: 'NumBet', label: 'เลขที่แทง' },
      ],
      LottoHeadList: [],
      LottoSubList: [],
      SumAmount: 0,
      AmountBet: null,
      AmountBet2: null,
      NumBet: '',
      NumArrCart: '',
      NumArrCartInput: null,
      otp: '',
      Status: 'Diposit',
      Clicked: 2,
      ShowDigit: 3,
      CheckBetType: true,
      maxlength: 3,
      // BetType: ['3ตัวบน', 'กลับตัวเลข'],
      BetType: ['3ตัวบน', '3ตัวโต๊ด'],
      Interval: null,
      IntervalRate: null,
      fields: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
        { key: 'delete', label: 'ลบ', thStyle: { width: '5%' } },
      ],
      fieldss: [
        // { key: 'index', label: 'ลำดับ' },
        { key: 'num_lot', label: 'เลข' },
        { key: 'num_type', label: 'ประเภท' },
        { key: 'price', label: 'ยอด', thStyle: { width: '40%' } },
        { key: 'win_rate', label: 'จ่าย' },
        { key: 'win', label: 'ชนะ' },
      ],
      fieldBill: [
        // { key: 'index', label: 'ลำดับที่' },
        { key: 'remark', label: 'ชื่อลูกค้า', thStyle: { width: '25%' } },
        { key: 'lotto_type', label: 'ประเภทหวย', thStyle: { width: '20%' } },
        { key: 'amount', label: 'เงินเดิมพัน', thStyle: { width: '20%' } },
        {
          key: 'created_at',
          label: 'เวลาแทง',
          formatter: value => momenttz(value).tz('Asia/Bangkok').format('DD/MM/YYYY HH:mm:ss'),
          thStyle: { width: '20%' },
        },
        // { key: 'status', label: 'สถานะ', thStyle: { width: '15%' } },
        { key: 'actions', label: 'สถานะ', thStyle: { width: '15%' } },
      ],
      items: [],
      Historyitems: [],
      RateAll: {
        Three: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_f: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down_b: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        Two: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Tot: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
        One: {
          Up: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
          Down: {
            Rate: 0,
            Min: 0,
            Max: 0,
            BetLimit: 0,
            BetList: [],
          },
        },
      },
      MinAmount: 0,
      MaxAmount: 0,
      Title: {
        RoundID: '',
        LottoName: '',
        LottoTypeHead: null,
        LottoHead: null,
        LottoSubHead: null,
        LottoDetail: [],
        CloseBet: '',
        CloseDate: '',
        CloseDateName: '',
        ShowCloseDate: '',
        LimitU3: [],
        LimitD3: [],
        LimitB3: [],
        LimitT3: [],
        LimitU2: [],
        LimitD2: [],
        LimitT2: [],
        LimitU1: [],
        LimitD1: [],
        CloseBetU3: [],
        CloseBetD3: [],
        CloseBetB3: [],
        CloseBetT3: [],
        CloseBetU2: [],
        CloseBetD2: [],
        CloseBetT2: [],
        CloseBetU1: [],
        CloseBetD1: [],
      },
      CloseBet: {
        CloseU3: [],
        CloseD3: [],
        CloseB3: [],
        CloseT3: [],
        CloseU2: [],
        CloseD2: [],
        CloseT2: [],
        CloseU1: [],
        CloseD1: [],
      },
      CloseBetModal: [],
      Slip: {
        SumAmount: null,
        date: null,
        time: null,
        listID: null,
        count: null,
        LottoHeadName: null,
      },
      statusCheck: false,
      Num_Arr: null,
      MainWallet: 0,
      NameShow: '',
      step1: false,
      NumArrCartInputStep1: '',
      NumArrCartInputStep1Show: [],
      NumArrCartInputStep2Show: [],
      NumArrCartInputStep2Cal: [],
      remarkBill: '',
      CloseTimeShow: '',
      currentPage: 1,
      perPage: 5,
      HistoryList: [],
      totalRows: 0,
      pageOptions: [5, 10, 15, 20],
      selectNumbet3U: false,
      selectNumbet3T: false,
      selectNumbet3D: false,
      selectNumbet2U: false,
      selectNumbet2T: false,
      selectNumbet2D: false,
      selectNumbet1U: false,
      selectNumbet1D: false,
      SubThree0: true,
      SubThree1: false,
      SubThree2: false,
      SubThree3: false,
      SubThree4: false,
      SubThree5: false,
      SubThree6: false,
      SubThree7: false,
      SubThree8: false,
      SubThree9: false,
      PickThreeArr: this.GenNum(1000, 3),
      PickTwoArr: this.GenNum(100, 2),
      PickOneArr: this.GenNum(10, 1),
      PickThree: 0,
      NineDoor: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      RootFront: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      RootBack: {
        D0: false,
        D1: false,
        D2: false,
        D3: false,
        D4: false,
        D5: false,
        D6: false,
        D7: false,
        D8: false,
        D9: false,
      },
      TwoPushSubType: {
        NineDoor: false,
        RootFront: false,
        RootBack: false,
        Ble: false,
        TwoHight: false,
        TwoLow: false,
        TwoEven: false,
        TwoOdd: false,
      },
      pricebase: 0,
    }
  },
  beforeDestroy() {
    clearInterval(this.Interval)
    clearInterval(this.IntervalTime)
  },
  created() {
  },
  async mounted() {
    this.ShowWallet()
    this.GetDataLotto()
    this.IntervalTime = setInterval(() => {
      this.ShowWallet()
      this.CloseTimeShow = this.CalDiffTime()
      if (this.CloseTimeShow === '0 วัน 00 : 00 : 00') {
        this.$router.push({ name: 'tanghuay-round' })
      }
    }, 500)
  },
  methods: {
    TogType(Ele, Num) {
      if (Num === 3) {
        if (Ele === 'up') {
          this.selectNumbet3U = !this.selectNumbet3U
        }
        if (Ele === 'tot') {
          this.selectNumbet3T = !this.selectNumbet3T
          this.selectNumbet3D = false
        }
        if (Ele === 'flip') {
          this.selectNumbet3D = !this.selectNumbet3D
          this.selectNumbet3T = false
        }
        if (!this.Mode) {
          this.selectNumbet2U = false
          this.selectNumbet2D = false
          this.selectNumbet2T = false
        }
        this.selectNumbet1U = false
        this.selectNumbet1D = false
      } else if (Num === 2) {
        if (Ele === 'up') {
          this.selectNumbet2U = !this.selectNumbet2U
        }
        if (Ele === 'down') {
          this.selectNumbet2D = !this.selectNumbet2D
        }
        if (Ele === 'flip') {
          this.selectNumbet2T = !this.selectNumbet2T
        }
        if (!this.Mode) {
          this.selectNumbet3U = false
          this.selectNumbet3D = false
          this.selectNumbet3T = false
        }
        this.selectNumbet1U = false
        this.selectNumbet1D = false
      } else if (Num === 1) {
        if (Ele === 'up') {
          this.selectNumbet1U = !this.selectNumbet1U
        }
        if (Ele === 'down') {
          this.selectNumbet1D = !this.selectNumbet1D
        }
        this.selectNumbet3U = false
        this.selectNumbet3D = false
        this.selectNumbet3T = false
        this.selectNumbet2U = false
        this.selectNumbet2D = false
        this.selectNumbet2T = false
      }
    },
    TogThreeType(Ele, Len) {
      if (Len === 3) {
        this.SubThree0 = false
        this.SubThree1 = false
        this.SubThree2 = false
        this.SubThree3 = false
        this.SubThree4 = false
        this.SubThree5 = false
        this.SubThree6 = false
        this.SubThree7 = false
        this.SubThree8 = false
        this.SubThree9 = false
        this.PickThree = Ele
        if (Ele === 0) {
          this.SubThree0 = true
        } else if (Ele === 100) {
          this.SubThree1 = true
        } else if (Ele === 200) {
          this.SubThree2 = true
        } else if (Ele === 300) {
          this.SubThree3 = true
        } else if (Ele === 400) {
          this.SubThree4 = true
        } else if (Ele === 500) {
          this.SubThree5 = true
        } else if (Ele === 600) {
          this.SubThree6 = true
        } else if (Ele === 700) {
          this.SubThree7 = true
        } else if (Ele === 800) {
          this.SubThree8 = true
        } else if (Ele === 900) {
          this.SubThree9 = true
        }
      }
    },
    GenNum(value, Len) {
      const Arr = []
      // eslint-disable-next-line
      for (let i = 0; i < value; i++) {
        Arr.push({
          Num: this.addLeadingZeros(i, Len),
          Index: false,
          U3: false,
          T3: false,
          U2: false,
          D2: false,
          U1: false,
          D1: false,
        })
      }
      return Arr
    },
    addLeadingZeros(num, totalLength) {
      return String(num).padStart(totalLength, '0')
    },
    PickNum(item, Arr) {
      if (this.selectNumbet3D) {
        const Switch3Arr = this.SwitchNum3(item.Num)
        this.CheckPickNum(Switch3Arr, Arr)
      } else if (this.selectNumbet2T) {
        const Switch2Arr = this.SwitchNum2(item.Num)
        this.CheckPickNum(Switch2Arr, Arr)
      } else {
        this.PushType(item)
      }
    },
    CheckPickNum(SwipArr, NumArr, ForCheck = true) {
      SwipArr.forEach(ele => {
        const IndexNum = NumArr.findIndex(({ Num }) => Num === ele)
        if (IndexNum >= 0) {
          this.PushType(NumArr[IndexNum], ForCheck)
        }
      })
    },
    async PushType(Arr, ForCheck = true) {
      if (this.selectNumbet3U) {
        // eslint-disable-next-line no-param-reassign
        Arr.U3 = !Arr.U3
        if (Arr.U3) {
          await this.PushItems(Arr.Num, '3ตัวบน', 3, 'UP', 1)
        } else {
          this.RemovePush(this.items, Arr.Num, '3ตัวบน')
        }
      }
      if (this.selectNumbet3T) {
        // eslint-disable-next-line no-param-reassign
        Arr.T3 = !Arr.T3
        if (Arr.T3) {
          await this.PushItems(Arr.Num, '3ตัวโต๊ด', 3, 'TOT', 1)
        } else {
          this.RemovePush(this.items, Arr.Num, '3ตัวโต๊ด')
        }
      }
      if (this.selectNumbet2U) {
        if (ForCheck) {
          // eslint-disable-next-line no-param-reassign
          Arr.U2 = !Arr.U2
          if (Arr.U2) {
            await this.PushItems(Arr.Num, '2ตัวบน', 2, 'UP', 1)
          } else {
            this.RemovePush(this.items, Arr.Num, '2ตัวบน')
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          Arr.U2 = true
          await this.PushItems(Arr.Num, '2ตัวบน', 2, 'UP', 1)
        }
      }
      if (this.selectNumbet2D) {
        if (ForCheck) {
          // eslint-disable-next-line no-param-reassign
          Arr.D2 = !Arr.D2
          if (Arr.D2) {
            await this.PushItems(Arr.Num, '2ตัวล่าง', 2, 'DOWN', 1)
          } else {
            this.RemovePush(this.items, Arr.Num, '2ตัวล่าง')
          }
        } else {
          // eslint-disable-next-line no-param-reassign
          Arr.D2 = true
          await this.PushItems(Arr.Num, '2ตัวล่าง', 2, 'DOWN', 1)
        }
      }
      if (this.selectNumbet1U) {
        // eslint-disable-next-line no-param-reassign
        Arr.U1 = !Arr.U1
        if (Arr.U1) {
          await this.PushItems(Arr.Num, 'วิ่งบน', 1, 'UP', 1)
        } else {
          this.RemovePush(this.items, Arr.Num, 'วิ่งบน')
        }
      }
      if (this.selectNumbet1D) {
        // eslint-disable-next-line no-param-reassign
        Arr.D1 = !Arr.D1
        if (Arr.D1) {
          await this.PushItems(Arr.Num, 'วิ่งล่าง', 1, 'DOWN', 1)
        } else {
          this.RemovePush(this.items, Arr.Num, 'วิ่งล่าง')
        }
      }
    },
    ActiveBet(Ele) {
      if (this.selectNumbet3U && this.selectNumbet3T) {
        return Ele.U3 || Ele.T3
      } if (this.selectNumbet3U) {
        return Ele.U3
      } if (this.selectNumbet3T) {
        return Ele.T3
      } if (this.selectNumbet2U && this.selectNumbet2D) {
        return Ele.U2 || Ele.D2
      } if (this.selectNumbet2U) {
        return Ele.U2
      } if (this.selectNumbet2D) {
        return Ele.D2
      } if (this.selectNumbet1U && this.selectNumbet1D) {
        return Ele.U1 || Ele.D1
      } if (this.selectNumbet1U) {
        return Ele.U1
      } if (this.selectNumbet1D) {
        return Ele.D1
      }
      return false
    },
    CheckTog(Type, Arr, Value) {
      let SwipChang = []
      let IndexNum = false
      if (Type === 'nine') {
        SwipChang = this.Swipe19(Value)
      } else if (Type === 'front') {
        SwipChang = this.SwipeFront(Value)
      } else if (Type === 'back') {
        SwipChang = this.SwipeBack(Value)
      }
      if (this.selectNumbet2U) {
        IndexNum = Arr.findIndex(({ Num, U2 }) => SwipChang.includes(Num) && !U2)
      } else if (this.selectNumbet2D) {
        IndexNum = Arr.findIndex(({ Num, D2 }) => SwipChang.includes(Num) && !D2)
      }
      if (IndexNum < 0) {
        return { SwipChang, status: true }
      }
      return { SwipChang, status: false }
    },
    RemovePush(Arr, Num, NumType) {
      // eslint-disable-next-line camelcase
      const IndexNum = Arr.findIndex(({ num_lot, num_type }) => num_lot === Num && num_type === NumType)
      if (IndexNum >= 0) {
        Arr.splice(IndexNum, 1)
      }
    },
    DisableInList(Arr, Type, Numlot) {
      const IndexNum = Arr.findIndex(({ Num }) => Num === Numlot)
      if (IndexNum >= 0) {
        // eslint-disable-next-line no-param-reassign
        Arr[IndexNum][Type] = false
      }
    },
    EnableInList(Arr, Type, Numlot) {
      const IndexNum = Arr.findIndex(({ Num }) => Num === Numlot)
      if (IndexNum >= 0) {
        // eslint-disable-next-line no-param-reassign
        Arr[IndexNum][Type] = true
      }
    },
    CalEnable(BetDigit, BetType, NumBet) {
      if (BetDigit === 3) {
        if (BetType === 'UP') {
          this.EnableInList(this.PickThreeArr, 'U3', NumBet)
        } else if (BetType === 'TOT') {
          this.EnableInList(this.PickThreeArr, 'T3', NumBet)
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          this.EnableInList(this.PickTwoArr, 'U2', NumBet)
        } else if (BetType === 'UP') {
          this.EnableInList(this.PickTwoArr, 'D2', NumBet)
        }
      } else if (BetDigit === 1) {
        if (BetType === 'UP') {
          this.EnableInList(this.PickOneArr, 'U1', NumBet)
        } else if (BetType === 'UP') {
          this.EnableInList(this.PickOneArr, 'D1', NumBet)
        }
      }
    },
    async CompleteNumBet() {
      if (this.selectNumbet3U && this.selectNumbet3T) {
        await this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', 1)
        await this.PushItems(this.NumBet, '3ตัวโต๊ด', 3, 'TOT', 1)
      } else if (this.selectNumbet3U && this.selectNumbet3D) {
        const Switch3Arr = this.SwitchNum3(this.NumBet)
        await Switch3Arr.forEach(ele => {
          this.PushItems(ele, '3ตัวบน', 3, 'UP', 1)
        })
      } else if (this.selectNumbet3U) {
        await this.PushItems(this.NumBet, '3ตัวบน', 3, 'UP', 1)
      } else if (this.selectNumbet3T) {
        await this.PushItems(this.NumBet, '3ตัวโต๊ด', 3, 'TOT', 1)
      }
      if (this.selectNumbet2U || this.selectNumbet2D) {
        let Num = this.NumBet
        if (this.NumBet.length > 2) {
          Num = this.NumBet.slice(1, 3)
        }
        if (this.selectNumbet2U) {
          if (this.TwoPushSubType.NineDoor) {
            const Res = this.Swipe19(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวบน', 2, 'UP', 1)
            })
          } else if (this.TwoPushSubType.RootFront) {
            const Res = this.SwipeFront(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวบน', 2, 'UP', 1)
            })
          } else if (this.TwoPushSubType.RootBack) {
            const Res = this.SwipeBack(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวบน', 2, 'UP', 1)
            })
          } else if (this.selectNumbet2T) {
            const Res = this.SwitchNum2(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวบน', 2, 'UP', 1)
            })
          } else {
            this.PushItems(Num, '2ตัวบน', 2, 'UP', 1)
          }
        }
        if (this.selectNumbet2D) {
          if (this.TwoPushSubType.NineDoor) {
            const Res = this.Swipe19(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', 1)
            })
          } else if (this.TwoPushSubType.RootFront) {
            const Res = this.SwipeFront(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', 1)
            })
          } else if (this.TwoPushSubType.RootBack) {
            const Res = this.SwipeBack(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', 1)
            })
          } else if (this.selectNumbet2T) {
            const Res = this.SwitchNum2(Num)
            await Res.forEach(ele => {
              this.PushItems(ele, '2ตัวล่าง', 2, 'DOWN', 1)
            })
          } else {
            this.PushItems(Num, '2ตัวล่าง', 2, 'DOWN', 1)
          }
        }
      }
      if (this.selectNumbet1U || this.selectNumbet1D) {
        if (this.selectNumbet1U) {
          await this.PushItems(this.NumBet, 'วิ่งบน', 1, 'UP', 1)
        }
        if (this.selectNumbet1D) {
          await this.PushItems(this.NumBet, 'วิ่งล่าง', 1, 'DOWN', 1)
        }
      }
      this.ClearAfterBet()
    },
    async PreSubmit() {
      let ValiU3 = true
      let ValiT3 = true
      let ValiU2 = true
      let ValiD2 = true
      let ValiU1 = true
      let ValiD1 = true
      if (this.items.filter(ele => ele.num_type === '3ตัวบน').length > 0) {
        ValiU3 = await this.$refs.BetItemsRulesU3.validate()
      }
      if (this.items.filter(ele => ele.num_type === '3ตัวโต๊ด').length > 0) {
        ValiT3 = await this.$refs.BetItemsRulesT3.validate()
      }
      if (this.items.filter(ele => ele.num_type === '2ตัวบน').length > 0) {
        ValiU2 = await this.$refs.BetItemsRulesU2.validate()
      }
      if (this.items.filter(ele => ele.num_type === '2ตัวล่าง').length > 0) {
        ValiD2 = await this.$refs.BetItemsRulesD2.validate()
      }
      if (this.items.filter(ele => ele.num_type === 'วิ่งบน').length > 0) {
        ValiU1 = await this.$refs.BetItemsRulesU1.validate()
      }
      if (this.items.filter(ele => ele.num_type === 'วิ่งล่าง').length > 0) {
        ValiD1 = await this.$refs.BetItemsRulesD1.validate()
      }
      if (ValiU3 && ValiT3 && ValiU2 && ValiD2 && ValiU1 && ValiD1) {
        const CheckPrice = this.items.some(el => el.price === '')
        if (CheckPrice === false) {
          this.CheckSubmit = false
          if (
            this.SumAmount > this.MainWallet
          ) {
            this.SwalMes(
              'warning',
              'จำนวนเงินไม่เพียงพอ !!',
              'ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
            )
          // this.items.splice(this.items.length - 1, 1)
          } else {
            this.confirm = true
          }
        }
      }
    },
    async GetDataLotto() {
      // console.log(this.$route.params.CloseDate)
      this.NameShow = this.$route.params.LottoName
      this.Title.LottoHead = this.$route.params.LottoHead
      this.Title.RoundID = this.$route.params.round_id
      if (this.$route.params.LottoSubHead === 'null' || this.$route.params.LottoSubHead === 'undefined') {
        this.$route.params.LottoSubHead = null
      }
      this.Title.LottoSubHead = this.$route.params.LottoSubHead
      await this.ShowTitle()
      await this.GetRate(this.Title.RoundID)
    },
    CalDiffTime() {
      const DateRound = momenttz(this.$route.params.CloseDate).tz('Asia/Bangkok')
      const DateNow = momenttz().tz('Asia/Bangkok')
      const Seconds = DateRound.diff(DateNow, 'seconds')

      const Day = Math.floor(Seconds / 86400)
      const Hours = Math.floor((Seconds % 86400) / 3600)
      const Minutes = Math.floor((Seconds % 3600) / 60)
      const numseconds = Math.floor((Seconds % 3600) % 60)
      if (Day >= 0) {
        return `${Day} วัน ${Hours < 10 ? `0${Hours}` : Hours} : ${
          Minutes < 10 ? `0${Minutes}` : Minutes
        } : ${numseconds < 10 ? `0${numseconds}` : numseconds}`
      }
      return null
    },
    showRule(data) {
      if (data === 'หวยรัฐบาล') {
        this.rule = 'หวยรัฐบาล'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยออมสิน') {
        this.rule = 'หวยออมสิน'
        this.$refs['my-rule'].show()
      } else if (data === 'หวย ธกส') {
        this.rule = 'หวย ธกส'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เช้า)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เที่ยง)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (บ่าย)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยหุ้นไทย (เย็น)') {
        this.rule = 'หวยหุ้นไทย'
        this.$refs['my-rule'].show()
      } else if (data === 'เยอรมัน') {
        this.rule = 'เยอรมัน'
        this.$refs['my-rule'].show()
      } else if (data === 'อังกฤษ') {
        this.rule = 'อังกฤษ'
        this.$refs['my-rule'].show()
      } else if (data === 'ลาว') {
        this.rule = 'ลาว'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (เช้า)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นนิเคอิ (บ่าย)') {
        this.rule = 'หุ้นนิเคอิ'
        this.$refs['my-rule'].show()
      } else if (data === 'สิงคโปร์') {
        this.rule = 'สิงคโปร์'
        this.$refs['my-rule'].show()
      } else if (data === 'ไต้หวัน') {
        this.rule = 'ไต้หวัน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (เช้า)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === ' จีน (บ่าย)') {
        this.rule = 'จีน'
        this.$refs['my-rule'].show()
      } else if (data === 'อินเดีย') {
        this.rule = 'อินเดีย'
        this.$refs['my-rule'].show()
      } else if (data === 'รัสเซีย') {
        this.rule = 'รัสเซีย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮานอย') {
        this.rule = 'ฮานอย'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (เช้า)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'ฮั่งเส็ง (บ่าย)') {
        this.rule = 'ฮั่งเส็ง'
        this.$refs['my-rule'].show()
      } else if (data === 'มาเลย์') {
        this.rule = 'มาเลย์'
        this.$refs['my-rule'].show()
      } else if (data === 'อียิปต์') {
        this.rule = 'อียิปต์'
        this.$refs['my-rule'].show()
      } else if (data === 'หวยยี่กี') {
        this.rule = 'หวยยี่กี'
        this.$refs['my-rule'].show()
      } else if (data === 'หุ้นเกาหลี') {
        this.rule = 'หุ้นเกาหลี'
        this.$refs['my-rule'].show()
      }
    },
    AddPriceBase(val) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i <= this.items.length - 1; i++) {
        this.items[i].price = val
      }
    },
    handleOnComplete() {
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 100)
      }
    },
    Betting(val) {
      if (this.NumBet.length < this.CalMaxLength()) {
        this.NumBet += val
      }
      if (this.NumBet.length === this.CalMaxLength()) {
        setTimeout(() => this.CompleteNumBet(), 200)
        // console.log(this.items)
      }
    },
    showAlertHide() {
      this.$refs['modal-alert'].hide()
    },
    showAlert(CloseBet) {
      this.CloseBetModal = CloseBet
      this.$refs['modal-alert'].show()
    },
    async SummitBet() {
      // console.log(this.items)
      const CheckPrice = this.items.some(el => el.price === '')
      if (CheckPrice === false) {
        this.CheckSubmit = false
        if (
          this.SumAmount > this.MainWallet
        ) {
          this.SwalMes(
            'warning',
            'จำนวนเงินไม่เพียงพอ !!',
            'ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
          )
          // this.items.splice(this.items.length - 1, 1)
        } else {
          this.show = true

          const Obj = {
            UserToken: localStorage.getItem('UserToken'),
            SumAmount: this.SumAmount,
            discount: this.discount,
            discount_remark: this.discount_member,
            UserData: null,
            remark: this.remark,
            OBJ: this.items,
          }
          // console.log(Obj)
          try {
            const { data: response } = await this.$http.post(
              'https://api.rmtlotto.com/api/seamless/deduct',
              Obj,
            )
            if (response) {
              this.items = []
              this.PickThreeArr = this.GenNum(1000, 3)
              this.PickTwoArr = this.GenNum(100, 2)
              this.PickOneArr = this.GenNum(10, 1)
              this.CheckSubmit = true
              this.confirm = false
              this.countArry = 0
              this.Slip.SumAmount = null
              this.Slip.date = null
              this.Slip.time = null
              this.Slip.listID = null
              this.Slip.count = null
              this.Slip.LottoHeadName = null
              this.remark = null
              this.NumArrCartInputStep1 = ''
              this.NumArrCartInputStep1Show = []
              this.NumArrCartInputStep2Show = []
              this.NumArrCartInputStep2Cal = []
              this.show = false
              this.ShowWallet()
              this.ShowTitle()
              this.GetRate(this.Title.RoundID)
              // await this.showModal(response)
              if (response.success === true) {
                // console.log(response)
                // await this.sleep(3000)
                // window.location.reload()
              } else {
                // this.showAlert(response.Mes)
              }
            }
          } catch (err) {
            this.SwalMes('error', 'ส่งโพยไม่สำเร็จ', 'ไม่สามารถส่งโพยได้ในขณะนี้')
            // console.log(err)
            this.CheckSubmit = true
          }
        }
      }
    },
    async sleep(ms) {
      return new Promise(resolve => {
        setTimeout(resolve, ms)
      })
    },
    async ShowWallet() {
      this.MainWallet = this.$store.state.appConfig.UserData.Balance
      this.discount = this.$store.state.appConfig.UserData.Discount
    },
    async ShowTitle() {
      const params = {
        RoundID: this.Title.RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/round/ShowByHeadAndSub/Buynow',
          { params },
        )
        if (ResData.success) {
          this.statusCheck = true
          // eslint-disable-next-line no-underscore-dangle
          this.Title.LottoTypeHead = ResData.mes.LottoTypeHead
          this.Title.CloseBet = ResData.mes.StopBetTime
          this.Title.CloseDate = ResData.mes.CloseDate
          this.Title.CloseDateName = ResData.mes.CloseDateName
        }
      } catch (e) {
        this.statusCheck = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'แจ้งเตือน',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'ไม่พบรอบที่เปิดรับแทง',
          },
        })
        // console.log(e)
      }
    },
    async GetRate(RoundID) {
      const params = {
        RoundID,
      }
      try {
        const { data: ResData } = await this.$http.get(
          'https://api.rmtlotto.com/api/member/ratebyround/showbetmore',
          { params },
        )
        if (ResData.success) {
          // console.log(ResData.mes)
          const Rate = ResData.mes.Data
          const ListBet = ResData.mes.LimitData
          // const ListBet = {
          //   B3: [],
          //   D1: [],
          //   D2: [],
          //   D3: [],
          //   T2: [],
          //   T3: [],
          //   U1: [],
          //   U2: [],
          //   U3: [],
          // }
          this.RateAll.Three.Up.Min = Rate.MinU3
          this.RateAll.Three.Down_f.Min = Rate.MinD3
          this.RateAll.Three.Down_b.Min = Rate.MinB3
          this.RateAll.Three.Tot.Min = Rate.MinT3
          this.RateAll.Two.Up.Min = Rate.MinU2
          this.RateAll.Two.Down.Min = Rate.MinD2
          this.RateAll.Two.Tot.Min = Rate.MinT2
          this.RateAll.One.Up.Min = Rate.MinU1
          this.RateAll.One.Down.Min = Rate.MinD1

          this.RateAll.Three.Up.Max = Rate.MaxU3
          this.RateAll.Three.Down_f.Max = Rate.MaxD3
          this.RateAll.Three.Down_b.Max = Rate.MaxB3
          this.RateAll.Three.Tot.Max = Rate.MaxT3
          this.RateAll.Two.Up.Max = Rate.MaxU2
          this.RateAll.Two.Down.Max = Rate.MaxD2
          this.RateAll.Two.Tot.Max = Rate.MaxT2
          this.RateAll.One.Up.Max = Rate.MaxU1
          this.RateAll.One.Down.Max = Rate.MaxD1

          this.RateAll.Three.Up.Rate = Rate.U3
          this.RateAll.Three.Down_f.Rate = Rate.D3
          this.RateAll.Three.Down_b.Rate = Rate.B3
          this.RateAll.Three.Tot.Rate = Rate.T3
          this.RateAll.Two.Up.Rate = Rate.U2
          this.RateAll.Two.Down.Rate = Rate.D2
          this.RateAll.Two.Tot.Rate = Rate.T2
          this.RateAll.One.Up.Rate = Rate.U1
          this.RateAll.One.Down.Rate = Rate.D1

          this.RateAll.Three.Up.BetLimit = Rate.BetLimitU3
          this.RateAll.Three.Down_f.BetLimit = Rate.BetLimitD3
          this.RateAll.Three.Down_b.BetLimit = Rate.BetLimitB3
          this.RateAll.Three.Tot.BetLimit = Rate.BetLimitT3
          this.RateAll.Two.Up.BetLimit = Rate.BetLimitU2
          this.RateAll.Two.Down.BetLimit = Rate.BetLimitD2
          this.RateAll.Two.Tot.BetLimit = Rate.BetLimitT2
          this.RateAll.One.Up.BetLimit = Rate.BetLimitU1
          this.RateAll.One.Down.BetLimit = Rate.BetLimitD1

          this.RateAll.Three.Up.BetList = ListBet.U3
          this.RateAll.Three.Down_f.BetList = ListBet.D3
          this.RateAll.Three.Down_b.BetList = ListBet.B3
          this.RateAll.Three.Tot.BetList = ListBet.T3
          this.RateAll.Two.Up.BetList = ListBet.U2
          this.RateAll.Two.Down.BetList = ListBet.D2
          this.RateAll.Two.Tot.BetList = ListBet.T2
          this.RateAll.One.Up.BetList = ListBet.U1
          this.RateAll.One.Down.BetList = ListBet.D1
          this.Title.CloseBetU3 = Rate.CloseBetU3.map(x => x.NumLot)
          this.Title.CloseBetD3 = Rate.CloseBetD3.map(x => x.NumLot)
          this.Title.CloseBetB3 = Rate.CloseBetB3.map(x => x.NumLot)
          this.Title.CloseBetT3 = Rate.CloseBetT3.map(x => x.NumSort)
          this.Title.CloseBetU2 = Rate.CloseBetU2.map(x => x.NumLot)
          this.Title.CloseBetD2 = Rate.CloseBetD2.map(x => x.NumLot)
          this.Title.CloseBetT2 = Rate.CloseBetT2.map(x => x.NumSort)
          this.Title.CloseBetU1 = Rate.CloseBetU1.map(x => x.NumLot)
          this.Title.CloseBetD1 = Rate.CloseBetD1.map(x => x.NumLot)
          this.Title.LimitU3 = Rate.LimitU3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD3 = Rate.LimitD3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitB3 = Rate.LimitB3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT3 = Rate.LimitT3.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU2 = Rate.LimitU2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD2 = Rate.LimitD2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitT2 = Rate.LimitT2.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitU1 = Rate.LimitU1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )
          this.Title.LimitD1 = Rate.LimitD1.sort(
            (a, b) => a.NumLot - b.NumLot,
          )

          this.CloseBet.CloseU3 = this.CalCloseBet(
            ListBet.U3,
            this.Title.CloseBetU3,
            Rate.BetLimitU3,
          )
          this.CloseBet.CloseD3 = this.CalCloseBet(
            ListBet.D3,
            this.Title.CloseBetD3,
            Rate.BetLimitD3,
          )
          this.CloseBet.CloseB3 = this.CalCloseBet(
            ListBet.B3,
            this.Title.CloseBetB3,
            Rate.BetLimitB3,
          )
          this.CloseBet.CloseT3 = this.CalCloseBet(
            ListBet.T3,
            this.Title.CloseBetT3,
            Rate.BetLimitT3,
          )
          this.CloseBet.CloseU2 = this.CalCloseBet(
            ListBet.U2,
            this.Title.CloseBetU2,
            Rate.BetLimitU2,
          )
          this.CloseBet.CloseD2 = this.CalCloseBet(
            ListBet.D2,
            this.Title.CloseBetD2,
            Rate.BetLimitD2,
          )
          this.CloseBet.CloseT2 = this.CalCloseBet(
            ListBet.T2,
            this.Title.CloseBetT2,
            Rate.BetLimitT2,
          )
          this.CloseBet.CloseU1 = this.CalCloseBet(
            ListBet.U1,
            this.Title.CloseBetU1,
            Rate.BetLimitU1,
          )
          this.CloseBet.CloseD1 = this.CalCloseBet(
            ListBet.D1,
            this.Title.CloseBetD1,
            Rate.BetLimitD1,
          )

          this.Title.LimitU3 = this.CalLimit(
            this.Title.LimitU3,
            this.CloseBet.CloseU3,
          )
          this.Title.LimitD3 = this.CalLimit(
            this.Title.LimitD3,
            this.CloseBet.CloseD3,
          )
          this.Title.LimitB3 = this.CalLimit(
            this.Title.LimitB3,
            this.CloseBet.CloseB3,
          )
          this.Title.LimitT3 = this.CalLimit(
            this.Title.LimitT3,
            this.CloseBet.CloseT3,
          )
          this.Title.LimitU2 = this.CalLimit(
            this.Title.LimitU2,
            this.CloseBet.CloseU2,
          )
          this.Title.LimitD2 = this.CalLimit(
            this.Title.LimitD2,
            this.CloseBet.CloseD2,
          )
          this.Title.LimitT2 = this.CalLimit(
            this.Title.LimitT2,
            this.CloseBet.CloseT2,
          )
          this.Title.LimitU1 = this.CalLimit(
            this.Title.LimitU1,
            this.CloseBet.CloseU1,
          )
          this.Title.LimitD1 = this.CalLimit(
            this.Title.LimitD1,
            this.CloseBet.CloseD1,
          )
        }
      } catch (e) {
        console.log(e)
      }
    },
    async PushItems(NumBet, NumType, BetDigit, BetType, Price) {
      // console.log(NumBet, NumType, BetDigit, BetType, Price)

      // eslint-disable-next-line
      new Promise((resolve, reject) => {
        const { Rate, RateLimit } = this.CalRate(NumBet, BetDigit, BetType)
        let NumSort = null
        let CheckDup = null
        if (BetType === 'TOT') {
          const NumArr = NumBet.split('')
          NumArr.sort()
          NumSort = NumArr.join('')
          CheckDup = this.items.findIndex(
            obj => obj.NumSort === NumSort
                            && obj.bet_digit === BetDigit
                            && obj.bet_type === BetType,
          )
        } else {
          CheckDup = this.items.findIndex(
            obj => obj.num_lot === NumBet
                            && obj.bet_digit === BetDigit
                            && obj.bet_type === BetType,
          )
        }
        if (CheckDup >= 0) {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price) + Number(this.items[CheckDup].price),
          )
          if (CheckLimit.status === true) {
            this.items[CheckDup].price = Number(Price) + Number(this.items[CheckDup].price)
          } else if (CheckLimit.amount <= 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
          }
        } else {
          const CheckLimit = this.CheckBetLimit(
            NumBet,
            BetDigit,
            BetType,
            Number(Price),
          )
          if (CheckLimit.status === true) {
            if (RateLimit) {
              this.SwalMes('info', 'หมายเลขจ่ายตาม %', `${NumType} หมายเลข ${NumBet} จ่าย ${Rate} บาท`)
            }
            // eslint-disable-next-line no-shadow
            const ObjItems = {
              num_lot: NumBet,
              num_type: NumType,
              bet_digit: BetDigit, // 1, 2 ,3
              bet_type: BetType, // TOT , UP , DOWN
              price: Price,
              round_id: this.Title.RoundID,
              LottoTypeHead: this.Title.LottoTypeHead,
              LottoHead: this.Title.LottoHead,
              LottoSubHead: this.Title.LottoSubHead
                ? this.Title.LottoSubHead
                : null,
              win_rate: Rate,
              NumSort,
              RateLimit,
            }
            this.items.push(ObjItems)
            this.CalEnable(BetDigit, BetType, NumBet)
            resolve()
          } else if (CheckLimit.amount === 0) {
            this.SwalMes('error', 'ปิดรับแทง', `${NumType} หมายเลข ${NumBet}`)
            reject()
          } else {
            this.SwalMes(
              'error',
              'ไม่สำเร็จ !',
              `${NumType} หมายเลข ${NumBet} รับแทงไม่เกิน ${this.Commas(
                CheckLimit.amount,
              )} บาท`,
            )
            reject()
          }
        }
      })
    },
    CalCloseBet(Arr, CloseList, BetLimit) {
      // console.log(Arr, CloseList, BetLimit)
      let arrArr = Arr.filter(ele => ele.totalAmount >= BetLimit)
      // eslint-disable-next-line no-underscore-dangle
      arrArr = arrArr.map(x => x._id)
      if (CloseList.length > 0) {
        arrArr.push(...CloseList)
      }
      return arrArr.sort()
    },
    CalRate(NumBet, BetDigit, BetType) {
      let Rate = 0
      let IndexLimit = null
      let RateLimit = false
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD1.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD1[IndexLimit].Rate
          } else {
            Rate = this.RateAll.One.Down.Rate
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Up.Rate
          }
        } else if (BetType === 'DOWN') {
          IndexLimit = this.Title.LimitD2.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Down.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT2[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Two.Tot.Rate
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          IndexLimit = this.Title.LimitU3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitU3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Up.Rate
          }
        } else if (BetType === 'DOWN_F') {
          IndexLimit = this.Title.LimitD3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitD3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_f.Rate
          }
        } else if (BetType === 'DOWN_B') {
          IndexLimit = this.Title.LimitB3.findIndex(
            ({ NumLot }) => NumLot === NumBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitB3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Down_b.Rate
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          IndexLimit = this.Title.LimitT3.findIndex(
            ({ NumSort }) => NumSort === SortBet,
          )
          if (IndexLimit >= 0) {
            Rate = this.Title.LimitT3[IndexLimit].Rate
          } else {
            Rate = this.RateAll.Three.Tot.Rate
          }
        }
      }
      if (IndexLimit >= 0) {
        RateLimit = true
      }
      return { Rate, RateLimit }
    },
    CalLimit(Limit, CloseBet) {
      return Limit.filter(val => !CloseBet.includes(val.NumLot))
    },
    ClearAfterBet() {
      this.NumBet = ''
      // this.AmountBet = 1
      // this.BugOtp = false
      // // eslint-disable-next-line no-return-assign
      // setTimeout(() => (this.BugOtp = true), 10)
    },
    SelctTwoTool(Val) {
      if (this.selectNumbet2U && this.selectNumbet2D) {
        this.SelectBetType2(Val, '2ตัวบน', 'UP')
        this.SelectBetType2(Val, '2ตัวล่าง', 'DOWN')
      } else if (this.selectNumbet2U) {
        this.SelectBetType2(Val, '2ตัวบน', 'UP')
      } else if (this.selectNumbet2D) {
        this.SelectBetType2(Val, '2ตัวล่าง', 'DOWN')
      }
    },
    async SelectBetType2(Val, NumType, NumTypePrefix) {
      if (Val === 'สองตัวคี่') {
        const Res = this.SwipeOdd()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      } else if (Val === 'สองตัวคู่') {
        const Res = this.SwipeEven()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      } else if (Val === 'สองตัวสูง') {
        const Res = this.SwipeHigh()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      } else if (Val === 'สองตัวต่ำ') {
        const Res = this.SwipeLow()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      } else if (Val === 'เลขเบิ้ล') {
        const Res = this.SwipeDobble()
        await Res.forEach(ele => {
          this.PushItems(
            ele,
            NumType,
            2,
            NumTypePrefix,
            NumTypePrefix === 'UP'
              ? this.RateAll.Two.Up.Min
              : this.RateAll.Two.Down.Min,
          )
        })
      }
    },
    TestPrice(Price, Min, Max) {
      if (Price < Min || Price > Max) {
        return true
      }
      return false
    },
    CheckAmount(Val, Price, Index, NumBet = null) {
      const MinMax = { Min: 0, Max: 0 }
      let BetDigit = 3
      let BetType = 'UP'
      if (Val === '3ตัวบน') {
        MinMax.Min = this.RateAll.Three.Up.Min
        MinMax.Max = this.RateAll.Three.Up.Max
      } else if (Val === '3ตัวหน้า') {
        MinMax.Min = this.RateAll.Three.Down_f.Min
        MinMax.Max = this.RateAll.Three.Down_f.Max
        BetType = 'DOWN_F'
      } else if (Val === '3ตัวท้าย') {
        MinMax.Min = this.RateAll.Three.Down_b.Min
        MinMax.Max = this.RateAll.Three.Down_b.Max
        BetType = 'DOWN_B'
      } else if (Val === '3ตัวโต๊ด') {
        MinMax.Min = this.RateAll.Three.Tot.Min
        MinMax.Max = this.RateAll.Three.Tot.Max
        BetType = 'TOT'
      } else if (Val === '2ตัวบน') {
        MinMax.Min = this.RateAll.Two.Up.Min
        MinMax.Max = this.RateAll.Two.Up.Max
        BetDigit = 2
        BetType = 'UP'
      } else if (Val === '2ตัวล่าง') {
        MinMax.Min = this.RateAll.Two.Down.Min
        MinMax.Max = this.RateAll.Two.Down.Max
        BetDigit = 2
        BetType = 'DOWN'
      } else if (Val === '2ตัวโต๊ด(บน)') {
        MinMax.Min = this.RateAll.Two.Tot.Min
        MinMax.Max = this.RateAll.Two.Tot.Max
        BetDigit = 2
        BetType = 'TOT'
      } else if (Val === 'วิ่งบน') {
        MinMax.Min = this.RateAll.One.Up.Min
        MinMax.Max = this.RateAll.One.Up.Max
        BetDigit = 1
        BetType = 'UP'
      } else if (Val === 'วิ่งล่าง') {
        MinMax.Min = this.RateAll.One.Down.Min
        MinMax.Max = this.RateAll.One.Down.Max
        BetDigit = 1
        BetType = 'DOWN'
      }
      if (Price) {
        if (Price < MinMax.Min) {
          this.items[Index].price = MinMax.Min
        } else if (Price > MinMax.Max) {
          this.items[Index].price = MinMax.Max
        }
        const CheckLimit = this.CheckBetLimit(
          NumBet,
          BetDigit,
          BetType,
          Number(Price),
        )
        if (CheckLimit.status === false) {
          this.items[Index].price = CheckLimit.amount > 0 ? CheckLimit.amount : 0
        }
      }
      return MinMax
    },
    CheckBetLimit(NumBet, BetDigit, BetType, Price) {
      let TotalAmount = Price
      let SumAmount = 0
      if (BetDigit === 1) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD1.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.One.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.One.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.One.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 2) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN') {
          const CloseIn = this.CloseBet.CloseD2.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Down.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Down.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Down.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT2.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Two.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Two.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Two.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      } else if (BetDigit === 3) {
        if (BetType === 'UP') {
          const CloseIn = this.CloseBet.CloseU3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Up.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Up.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Up.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_F') {
          const CloseIn = this.CloseBet.CloseD3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_f.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_f.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_f.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'DOWN_B') {
          const CloseIn = this.CloseBet.CloseB3.indexOf(NumBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Down_b.BetList.findIndex(
            ({ _id }) => _id === NumBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Down_b.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Down_b.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        } else if (BetType === 'TOT') {
          const BetArr = NumBet.split('')
          BetArr.sort()
          const SortBet = BetArr.join('')
          const CloseIn = this.CloseBet.CloseT3.indexOf(SortBet)
          if (CloseIn >= 0) {
            return { status: false, amount: 0 }
          }
          const Index = this.RateAll.Three.Tot.BetList.findIndex(
            ({ _id }) => _id === SortBet,
          )
          if (Index >= 0) {
            TotalAmount = this.RateAll.Three.Tot.BetList[Index].totalAmount + Price
          }
          SumAmount = this.RateAll.Three.Tot.BetLimit - TotalAmount
          if (SumAmount < 0) {
            return { status: false, amount: SumAmount + Price }
          }
        }
      }
      return { status: true }
    },
    // 3Digit
    SwitchNum3(textnum) {
      const ReArr = []
      const num1 = textnum.substr(0, 1)
      const num2 = textnum.substr(1, 1)
      const num3 = textnum.substr(2, 1)
      if (num1 === num2 || num1 === num3 || num2 === num3) {
        if (num1 === num2) {
          ReArr.push(num1 + num1 + num3, num1 + num3 + num1, num3 + num1 + num1)
        } else if (num1 === num3) {
          ReArr.push(num1 + num2 + num1, num1 + num1 + num2, num2 + num1 + num1)
        } else {
          ReArr.push(num1 + num2 + num2, num2 + num2 + num1, num2 + num1 + num2)
        }
      } else {
        ReArr.push(
          num1 + num2 + num3,
          num1 + num3 + num2,
          num2 + num1 + num3,
          num2 + num3 + num1,
          num3 + num1 + num2,
          num3 + num2 + num1,
        )
      }
      return ReArr
    },
    // 2Digit
    SwitchNum2(textnum) {
      const ReArr = []
      const Val = String(textnum)
      const num1 = Val.substr(0, 1)
      const num2 = Val.substr(1, 1)
      if (num1 === num2) {
        ReArr.push(num1 + num2)
      } else {
        ReArr.push(num1 + num2, num2 + num1)
      }
      return ReArr
    },
    SwipeFront(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = String(Val) + i
        ReArr.push(R1)
      }
      return ReArr
    },
    SwipeBack(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      return ReArr
    },
    Swipe19(Val) {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        const R1 = i + String(Val)
        ReArr.push(R1)
      }
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        if (i === Number(Val)) {
          // eslint-disable-next-line no-plusplus
          i++
        }
        if (i !== 10) {
          const R2 = String(Val) + i
          ReArr.push(R2)
        }
      }
      return ReArr
    },
    SwipeLow() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 50; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeHigh() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 50; i < 100; i++) {
        if (i < 10) {
          ReArr.push(`0${i}`)
        } else {
          ReArr.push(String(i))
        }
      }
      return ReArr
    },
    SwipeOdd() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeEven() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 100; i++) {
        if (i % 2 !== 1) {
          if (i < 10) {
            ReArr.push(`0${i}`)
          } else {
            ReArr.push(String(i))
          }
        }
      }
      return ReArr
    },
    SwipeDobble() {
      const ReArr = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < 10; i++) {
        ReArr.push(`${i}${i}`)
      }
      return ReArr
    },
    Undo() {
      this.items = []
      this.Historyitems = []
      this.countArry = 0
      this.PickThreeArr = this.GenNum(1000, 3)
      this.PickTwoArr = this.GenNum(100, 2)
      this.PickOneArr = this.GenNum(10, 1)
      // if (this.Historyitems.length > 1) {
      //   this.Historyitems.pop()
      //   this.items = this.Historyitems[this.Historyitems.length - 1]
      // } else {
      //   this.items = []
      //   this.Historyitems = []
      // }
    },
    CalMaxLength() {
      if (this.selectNumbet3U || this.selectNumbet3T) {
        this.CheckBetType = false
        this.maxlength = 3
      } else if (this.selectNumbet2U || this.selectNumbet2D) {
        this.CheckBetType = false
        if (this.TwoPushSubType.NineDoor || this.TwoPushSubType.RootFront || this.TwoPushSubType.RootBack) {
          this.maxlength = 1
        } else {
          this.maxlength = 2
        }
      } else if (this.selectNumbet1U || this.selectNumbet1D) {
        this.CheckBetType = false
        this.maxlength = 1
      } else {
        this.CheckBetType = true
      }

      return this.maxlength
    },
    CalSumAmount() {
      const SumAmount = this.items.reduce(
        (acc, ele) => acc + Number(ele.price),
        0,
      )
      if (SumAmount > this.MainWallet) {
        if (this.CheckSubmit) {
          this.SwalMes(
            'warning',
            'จำนวนเงินไม่เพียงพอ !!',
            'ยอดเงินของท่านไม่เพียงพอกรุณาฝากเงินก่อนค่ะ',
          )
          this.CheckSubmit = !this.CheckSubmit
        }
      } else {
        this.CheckSubmit = true
      }
      if (this.discount_member > 0) {
        this.SumAmount = SumAmount - ((this.discount_member * SumAmount) / 100)
        return this.SumAmount
      }
      this.SumAmount = SumAmount
      return this.SumAmount
    },
    // eslint-disable-next-line consistent-return
    Commas(x) {
      if (x) {
        if (Math.round(x) !== x && x > 0) {
          const Num = Number(x)
          const y = Num.toFixed(2)
          return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        }
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return 0
    },
    SwalMes(Type, Title, Html) {
      this.$swal({
        icon: Type,
        title: Title,
        html: Html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    toggleModal() {
      this.$refs['my-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>
.row {
    margin-right: -.2rem;
    margin-left: 0.1rem;
}
.flex-fill {
  padding: 1px;
}
.view-rules {
    display: inline-flex;
    align-items: center;
    border: 0;
    text-align: center;
    background-color: #FCC201;
    border-radius: 13px;
    font-size: 12px;
    font-weight: 700;
    padding: 5px 15px;

}
td {
  border: 1px solid #707070af;
  background: #fff;
  padding: 5px;
}
tr {
  margin: auto;
  width: 320px;
}
.form-css {
  border: none !important;
  border-radius: 10px !important;
}
.btns-bet-l {
    width: calc(100% - 110px);
}
.row-top-action {
    background-color: rgb(58, 58, 58) !important;
    /* background-image: url(/back_bg.svg) !important; */
}
.row-bottom-action {
    background-color: rgb(58, 58, 58) !important;
    /* background-image: url(/back_bg.svg) !important; */
}
@media only screen and (max-width: 768px){
  .row-bottom-action {
      width: 100%!important;
      left: 0;
      bottom: 0;
  }
}
@media (max-width: 768px){
.bet-action-bottom .bet-detail {
    width: 50%;
    border-bottom: 1px solid #7f0000;
}
}
.bet-action-bottom .bet-detail {
    min-width: 140px;
    padding: 10px 20px;
    border-right: 1px solid #7f0000;
}
.input-bottom-bet {
    width: calc(100% - 90px);
    height: 44px;
    padding: 10px 15px;
    font-size: 20px;
    font-weight: 700;
    color: #FF6700;
    border: none;
    border-radius: 4px;
}
@media (max-width: 768px){
.bet-action-bottom .bet-button, .bet-action-bottom .bet-form {
    margin: 0 auto;
    width: 100%;
}
}
.bet-button {
    padding: 5px 20px 15px;
    width: 360px;
    display: flex;
}
.float-left {
    float: left!important;
}
a:focus, input {
    outline: 0;
}
.color-sub {
    color: #fff;
}
.color-white {
    color: #FFF;
}
.font-page-title {
    font-size: 20px;
}
.bet-list-item.yellow {
    color: #edad0c;
}
.bet-list-item.orange {
    color: #FF6700;
}
.bet-list-item.green {
    color: #2CA468;
}
.bet-list-item.yellow.selected, .bet-list-item.yellow.selected:hover {
    background-color: #FCC201;
    color: #FFF;
}
.bet-list-item {
    float: left;
    width: 100%;
    border-radius: 15px;
    background-color: #FFF;
    line-height: 20px;
    color: #818181;
    font-size: 12px;
    padding: 5px;
    border: 1px solid rgba(0,0,0,.125);
    box-shadow: 0 1px 2px rgb(0 0 0 / 5%);
}
.font-page-title {
    font-size: 20px;
}
.box-bet-coin-white .box-footer, .font-sub-title {
    font-size: 12px;
}
.bet-sm-item {
    width: 100%;
    height: 30px;
    line-height: 30px;
    border-radius: 15px;
    color: #818181;
    text-align: center;
    background-color: #FFF;
    display: block;
}
.btn1.active {
    background: #3b5898 !important;
    color: #fff !important;
  }
  .button-num-cal {
    width: 100%;
    background: linear-gradient(180deg,#FFF 0,#F1F1F1 100%);
    border: none;
    margin: 0 0 10px;
    font-size: 30px;
}
.button-num-cals {
    width: 100%;
height: 45px;
    background: linear-gradient(180deg,#424242 0,#202020 100%);
    border: none;
    color: #fff;
    border-radius: 4px;
    margin: 0 0 10px;
    font-size: 18px;
}
.button-num-calss {
    width: 100%;
    background: linear-gradient(180deg,#424242 0,#202020 100%);
    border: none;
    height: 45px;
    border-radius: 4px;
    color: #fff;
    margin: 0 0 10px;
    font-size: 30px;
}
.button-action-cal, .button-num-cal {
    box-shadow: 1px 1px 2px rgb(0 0 0 / 25%);
    border-radius: 4px;
    text-align: center;
    height: 45px;
}

.clicked{
    background: #3b5898 !important;
    color: #fff !important;
}
.btns, .input-2-box .text-control {
    font-weight: 700;
    padding: 10px;
    line-height: 24px;
}
.bg-cradit {
  margin: auto;
  background-color: #fff;
  color: #000;
  width: 150px;
  font-size: 0.6rem;
  border-radius: 10px;
}

.condition-top {
  background-color: #d40000;
  border-radius: 10px 10px 0px 0px;
  padding: 5px;
  color: #fff;
}
.condition-body {
  background-color: #838383;
  border-radius: 0px 0px 10px 10px;
  color: #fff;
}
.btn-type3 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  /* border-radius: 25px; */
  font-weight: 700;
  border: 0px;
}
.btn-type2 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}
.btn-type1 {
  width: 100%;
  color: #707070;
  background-color: rgba(0, 0, 0, 0);
  padding: 10px 2px;
  font-weight: 700;
  /* border-radius: 25px; */
  border: 0px;
}

.active,
.btnp:focus {
  border-bottom: 4px solid #0c2574;
  color: #0c2574;
}

.header-bg-content2 {
  background-color: #fbc02d;
  border-bottom: 2px solid #0c2574;
}

.bg-side {
  background-color: #111;
  border-radius: 5px;
  padding: 2px;
  color: #fff;
}
.pdd {
  margin: auto;
  max-width: 540px;
}
hr {
  margin-top: -2px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(221, 221, 221, 0.75),
    rgba(0, 0, 0, 0)
  );
}
p {
  line-height: 0.7;
}

.bg-danger {
  background-color: #dc3545 !important;
}
.text-white {
  color: #fff !important;
}
@media screen and (max-width: 768px) {
  .btn-tanghuay {
    font-size: 12px !important;
    padding-right: 4px;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-tanghuay {
  line-height: 1;
}
.action-bar.bar-white .nav-left{
    color: rgb(0, 0, 0) !important;
    font-weight: 700;
}
.action-bar.bar-white .nav-right{
    color: rgb(0, 0, 0) !important;
}
</style>

<style lang="scss" scoped>
@import url(https://unpkg.com/@8bu/vue-otp-input@1.1.6/dist/vue-otp-input.css);
@import '@core/scss/vue/pages/page-auth.scss';
.form-control {
    padding: 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d8d6de;
    border-radius: 0rem;
}
.style-1 {
  .otp-input-8-field input{
    border-radius: 5;
    border: 2px solid #c50000;
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.col-4,
.col-sm-6 {
  padding: 0.18rem;
  max-width: 100%;
  flex-grow: 1;
}

</style>
